import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
    state:{
      // 缓存组件页面
      catch_components: [],  
      // 菜单项 - 默认包含首页
      tabList: [
          {url:'/main/promanage', name: '项目管理'}
      ],
      // 当前选中的菜单 - 默认选择首页
      active: '/main/promanage',
        token: null,
        tokenExpire: null,
        tagsStoreList: [],
        language: Cookies.get('language') || 'en',
    },
    mutations: {
        // 添加keepalive缓存
        addKeepAliveCache(state, val){
            // 添加时判断，如果该组件已存在，则不添加
            if(state.catch_components.indexOf(val) === -1) {
                // 不存在，缓存页面
                state.catch_components.push(val)
            }
        },
        // 跳转页面执行
        selectMenu(state, submenu) {
            // 当前选中菜单
            var activePath = submenu.name
            // 历史已选中菜单列表
            var oldTabList = state.tabList
            // 将菜单信息添加到tablist - 添加时判断是否已有该标签
            var result = oldTabList.some(item => {
                if(item.name === activePath){
                    return true 
                } 
            })
            // 如果不包含该对象，则添加
            if(!result){
                oldTabList.push({
                    url: submenu.url,
                    name: submenu.name,
                })
            }
            // 重新赋值
            state.active = submenu.url
            state.tabList = oldTabList
        },
        // 点击标签选择菜单
        changeMenu(state, val) {
            state.active = val
        },
        // 删除keepalive缓存
        removeKeepAliveCache(state, val){
            let cache = state.catch_components
            for(let i = 0;i < cache.length;i++){
              if(cache[i] === val){
                  cache.splice(i, 1);
              }
            }
            state.catch_components = cache
        },
        //关闭菜单
        closeTab(state, val) {
            // 重新赋值
            state.active = val.active
            state.tabList = val.tabList
        },
        saveToken(state, data) {
            state.token = data;
            window.localStorage.setItem("Token", data);
        },
        saveTokenExpire(state, data) {
            state.tokenExpire = data;
            window.localStorage.setItem("TokenExpire", data);
        },
        saveTagsData(state, data) {
            state.tagsStoreList = data;
            sessionStorage.setItem("Tags",data)
        },
        SET_LANGUAGE: (state, language) => {
            state.language = language
            Cookies.set('language', language)
        },
        clearToken(state){
            state.catch_components = [];
            state.tabList = [
                {url:'/modules/promanage1/promanage', name: '项目管理'}
            ];
            state.active = '/modules/promanage1/promanage';
            state.token = null;
            state.tokenExpire = null;
            state.tagsStoreList = [];
            state.language = Cookies.get('language') || 'en'; 
        }
    },
    actions: {
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language)
        },
  
    } 
})

export default store