<template>
  <div id="app"> 
    <!--放置出口-->
    <router-view></router-view>
  </div>
</template>
<script>
    export default {
        
    }
</script>

<style lang="less">
    html,body,#app{
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
    }
</style>
