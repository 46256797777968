import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import './assets/js/rem';
import store from './store/index';
import './assets/fonts/iconfont.css';
import './promissionRouter' 

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }
  next()
})

//全局注册组件
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,  //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
  render: h => h(App)
}).$mount('#app')
 