<template>
  <div class="container">
    <div class="top">
      <div class="logobox">
        <img src="../assets/imgs/logo2.png">
      </div>
      <div class="rightbox">
        <el-dropdown class="userbox" @command="handleCommand" trigger="click">
          <div class="el-dropdown-link">
            <div class="headportrait">
              {{ this.username.substring(0,1) }}
            </div>
            <div class="username">
              {{ this.username }} ({{ this.zhiwu }})<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item icon="el-icon-lock" command="a">个人中心</el-dropdown-item> -->
            <el-dropdown-item icon="el-icon-switch-button" style="color:red" command="b" @click="quit()">退出当前账号
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="center">
      <div class="left_div">
        <!-- default-active:表示默认激活的选项卡 -->
        <!-- <el-menu
          :default-active="active"
          class="mymennu"
          unique-opened
          router
          background-color="#fafafa"
        > 
          <el-menu-item index="/main/promanage" @click="promanage('/main/promanage')">
            <i class="el-icon-menu"></i>
            <span slot="title">项目管理</span>
          </el-menu-item>
          <el-menu-item index="/main/dataquery"  @click="dataquery('/main/dataquery')">
            <i class="el-icon-s-data"></i>
            <span slot="title">数据查询</span>
          </el-menu-item> 
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-s-tools"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item-group >
                <el-menu-item index="/main/department"  @click="department('/main/department')">部门管理</el-menu-item>
                <el-menu-item index="/main/role"  @click="role('/main/role')">角色管理</el-menu-item>
                <el-menu-item index="/main/personnel"  @click="personnel('/main/personnel')">人员管理</el-menu-item>
                <el-menu-item index="/main/mechanism"  @click="mechanism('/main/mechanism')">机构管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="/main/workpoint" @click="workpoint('/main/workpoint')">
              <i class="el-icon-s-claim"></i>
              <span slot="title">作业监测</span>
            </el-menu-item>
        </el-menu> -->
        <el-menu 
          :default-active="$route.path" 
          class="mymennu" 
          @open="handleopen" 
          @close="handleclose"
          @select="handleselect" 
          unique-opened 
          router 
          :collapse="collapsed" >

            <sidebar v-for="(menu,index) in routes" :key="index" :item="menu" />
        </el-menu>
      </div>
      <div class="right_div">
        <el-col :span="24" style="height: 100%;" class="content-wrapper" :class="collapsed?'content-collapsed':'content-expanded' ">
          <div class="tags" v-if="showTags"> 
            <div id="tags-view-container" class="tags-view-container">
              <scroll-pane ref="scrollPane" class="tags-view-wrapper">
                <router-link v-for="(tag,index) in tagsList" ref="tag" :key="tag.path"
                  :class="{'active': isActive(tag.path)}"
                  :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }" tag="span"
                  @click.middle.native="closeTags(index)" class="tags-view-item">
                  {{ tag.title }}
                  <span class="el-icon-close" @click.prevent.stop="closeTags(index)" />
                </router-link>
              </scroll-pane> 
            </div> 
            <!-- 其他操作按钮 -->
            <div class="tags-close-box">
              <el-dropdown @command="handleTags">
                <el-button size="mini">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu size="small" slot="dropdown">
                  <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                  <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="content-az router-view-withly"  style="overflow-y: scroll;height: 96%;margin-bottom: 20px;box-sizing: border-box;">
            <!-- 含有母版的路由页面 -->
            <router-view v-on:settagsext="settagsext"></router-view>
          </div>
        </el-col>
      </div>
    </div>
    <el-dialog title="请修改初始密码"  :visible.sync="dialogPwdFormVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form :model="Pwdform" ref="Pwdform" :rules="reqPwdform">
      <el-form-item label="输入新密码" prop="pwd">
        <el-input v-model="Pwdform.pwd" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="输入确定密码" prop="pwd2">
        <el-input v-model="Pwdform.pwd2" autocomplete="off" show-password></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer"> 
      <el-button type="primary" @click="onSubmitPwd">确 定</el-button>
    </div>
</el-dialog> 
  </div>

</template> 
<script>
import Sidebar from '../components/Sidebar';
import ScrollPane from '../components/ScrollPane'
import { mapState, mapMutations } from 'vuex';
import { userinfor1, editUserInfo } from '../apis/apis.js'
export default {
  components: { Sidebar, ScrollPane },
  data() {
    return {
      activePath: '项目管理',
      username: '',
      zhiwu: '', 
      NewsVisible: false,
      collapsed: false,
      zModalShadow: false,
      SidebarVisible: false,
      collapsedClass: 'menu-expanded',
      sysUserName: '',
      newsDialogCss: 'news-dialog',
      sysUserAvatar: '',
      tagsList: [],
      routes: [],
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      dialogPwdFormVisible:false,
      Pwdform:{
        pwd:'',
        pwd2:''
      },
      reqPwdform: {
        pwd: [
                        { required: true, message: '请输入新密码', trigger: 'blur' },
                    ],
         pwd2: [
                        { required: true, message: '请输入确认密码', trigger: 'blur' },
                    ],
      },
      userinfo:null,
    }
  },
  created() {
    // 第一次进入页面时，修改tag的值
    this.setTags(this.$route); 
    //读取折叠状态
    if (window.localStorage.collapse == 'true') {
      this.collapsed = false;
      this.collapse();
    } else {
      this.collapsed = true;
      this.collapse();
    }
    //获取用户信息
    var nowedit1 = localStorage.getItem('cftoken');
    userinfor1(nowedit1).then(res => {
      if (res.data.success) {
        this.username = res.data.response.uLoginName
        this.zhiwu = res.data.response.uRealName 

        if(res.data.response.uLoginPWD=='E10ADC3949BA59ABBE56E057F20F883E'){
          this.dialogPwdFormVisible= true;
        }
        this.userinfo = res.data.response;
       
      }
    })
     
  },
  computed: {
    ...mapState({ // 从 state 中的到的计算属性
      catch_components: state => state.catch_components,  // keepalive缓存
      tabList: state => state.tabList,  // tags菜单列表
      active: state => state.active, // 已选中菜单
    })
  },
  methods: {
    gotappath(Path) { 
      this.$router.replace({
        path: Path,
      });
    },
    handleOpen(key, keyPath) { 
    },
    toindex() {
      this.$router.replace({
        path: "/",
      });
    },
    handleClose(key, keyPath) { 
    },
    onSubmit() { 
    },
    myNews() { 
      this.newsDialogCss += ' show ';
      this.NewsVisible = true;
    },
    handleopen() { 
    },
    handleclose() { 
    },
    handleselect: function (a, b) { 
    },
    //退出及修改密码
    handleCommand(command) { 
      if (command == "a") {
        this.$router.push('/main/Managepwd');
        this.active = "";
      } else if (command == "b") {  
        var _this = this;
        this.$confirm('确认退出吗?', '提示', {

        }).then(() => {
          window.localStorage.removeItem('user');
          window.localStorage.removeItem('Token');
          window.localStorage.removeItem('TokenExpire');
          window.localStorage.removeItem('NavigationBar');
          window.localStorage.removeItem('refreshtime');
          window.localStorage.removeItem('router');
          sessionStorage.removeItem("Tags");
          global.antRouter = [];
          this.tagsList = [];
          this.routes = [];
          this.$store.commit("saveTagsData", "");
          this.username='';
          this.zhiwu='';
          this.$store.commit("clearToken","");
           
          _this.$router.replace('/login'); 
       
        }).catch(() => {

        });

      }
    },
    //折叠导航栏
    collapse: function () {
      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        this.collapsedClass = 'menu-collapsed';
        this.SidebarVisible = false;
      } else {
        this.collapsedClass = 'menu-expanded';
        if (window.screen.width < 680) {
          this.SidebarVisible = true;
        }
      }
      //记录折叠状态
      window.localStorage.collapse = this.collapsed;
    },
    showMenu(i, status) { 
      this.$refs.menuCollapsed.getElementsByClassName('submenu-hook-' + i)[0].style.display = status ? 'block' : 'none';
    },
    isActive(path) { 
      return path === this.$route.fullPath;
    },
    // 关闭单个标签
    closeTags(index) { 
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);

        this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
      } else {
        this.$router.push('/');
      }
    },
    // 关闭全部标签
    closeAll() { 
      this.tagsList = [];
      this.$router.push('/');
      sessionStorage.removeItem("Tags");
    },
    // 关闭其他标签
    closeOther() { 
      const curItem = this.tagsList.filter(item => {
        return item.path === this.$route.fullPath;
      })
      this.tagsList = curItem;


      sessionStorage.setItem("Tags", JSON.stringify(this.tagsList))
    },
    // 设置标签
    setTags(route) { 
      if (!route.meta.NoTabPage) {
        const isExist = this.tagsList.some(item => {
          return item.path === route.fullPath;
        })
        !isExist && this.tagsList.push({
          title: route.meta.title,
          path: route.fullPath,
          // name: route.matched[1].components.default.name
        })
      }
    },
    // 当关闭所有页面时隐藏
    handleTags(command) { 
      command === 'other' ? this.closeOther() : this.closeAll();
    },
    getUserInfoByToken(token) {
      var _this = this;
      var loginParams = { token: token };
      getUserByToken(loginParams).then(data => {
        this.logining = false;
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: 'error'
          });
        } else {

          _this.$notify({
            type: "success",
            message: `欢迎管理员：${data.response.uRealName} ！`,
            duration: 3000
          });

          _this.sysUserName = data.response.uRealName;
          window.localStorage.user = JSON.stringify(data.response)
        }
      });
    },
    settagsext(tag) {  
      for (const tag2 of this.tagsList) {
          if(tag2.path===tag.path){
            return
          }
      } 
      var tags =[];
      var stags = sessionStorage.getItem('Tags');
      if(stags!=null&&stags.length>0){
        tags = JSON.parse(sessionStorage.getItem('Tags')); 
      }
      tags.push(tag) 
      sessionStorage.setItem("Tags", JSON.stringify(tags))
      this.tagsList = tags;
    },
    onSubmitPwd() {
      this.$refs.Pwdform.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
        
            if(this.Pwdform.pwd!=this.Pwdform.pwd2){
              this.$message.error("两次密码不一致!");
              return;
            }

            var para={
                Id:this.userinfo.uID ,
                Name:this.userinfo.uRealName,
                OldPwd:'123456',
                NewPwd:this.Pwdform.pwd,
                Remark:this.userinfo.desc
            }

            editUserInfo(para).then((res) => { 
              if (res.data.success) {
                this.editLoading = false;
                //NProgress.done();
                this.$message({
                  message: res.data.msg,
                  type: "success",
                }); 
                this.dialogPwdFormVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });  
    },
  },
  mounted() {  
    var tags = sessionStorage.getItem('Tags') ? JSON.parse(sessionStorage.getItem('Tags')) : [];
  
    if (tags && tags.length > 0) {
      this.tagsList = tags;
    }


    var NavigationBar = JSON.parse(window.localStorage.router ? window.localStorage.router : null);
    // var NavigationBar = global.antRouter;

    if (this.routes.length <= 0 && NavigationBar && NavigationBar.length >= 0) {
      this.routes = NavigationBar;
    }

    // 折叠菜单栏


  },
  computed: {
    showTags() {
      if (this.tagsList.length > 1) {
        this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
      }
      return this.tagsList.length > 0;
    }
  },
  watch: {
    // 对router进行监听，每当访问router时，对tags的进行修改
    $route: async function (newValue, from) {
  
      this.setTags(newValue);

      const tags = this.$refs.tag 
      this.$nextTick(() => {
        if (tags) {
          for (const tag of tags) {
            if (tag.to.path === this.$route.path) {
           
              this.$refs.scrollPane.moveToTarget(tag, tags)
              // when query is different then update
              // if (tag.to.fullPath !== this.$route.fullPath) {
              //     this.$store.dispatch('tagsView/updateVisitedView', this.$route)
              // }
              break
            }
          }
        }
      })

    },

  },
};
</script>
<style>
  .menu-collapsed .el-icon-arrow-right:before {
      display: none;
  }

  .tags {
      position: relative;
      overflow: hidden;
      border: 1px solid #f0f0f0;
      background: #fafafa;
      height: 4%;
  }

  .tags ul {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      display: none;
  }

  .tags-li {
      float: left;
      margin: 3px 5px 2px 3px;
      border-radius: 3px;
      font-size: 13px;
      overflow: hidden;
      height: 23px;
      line-height: 23px;
      border: 1px solid #e9eaec;
      background: #fff;
      padding: 3px 5px 4px 12px;
      vertical-align: middle;
      color: #666;
      -webkit-transition: all .3s ease-in;
      transition: all .3s ease-in;
  }

  .tags-li-icon {
      cursor: pointer;
  }

  .tags-li:not(.active):hover {
      background: #f8f8f8;
  }

  .tags-li-title {
      float: left;
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
      color: #666;
      text-decoration: none;
  }

  .tags-li.active {
      /*color: #fff;*/
      /*border: 1px solid #10B9D3;*/
      /*background-color: #10B9D3;*/
  }

  .tags-li.active .tags-li-title {
      /*color: #fff;*/
  }

  .tags-close-box {
      box-sizing: border-box;
      text-align: center;
      z-index: 10; 
      margin-right: 1px;
      line-height: 2;
  }

</style>
<style>

  .tags-view-container {
      height: 100%;
      width: calc(100% - 60px);
      /*background: #fff;*/
      /*border-bottom: 1px solid #d8dce5;*/
      /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);*/
      float: left;
  }

  .tags-view-container .tags-view-wrapper .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
  }

  .tags-view-container .tags-view-wrapper .tags-view-item:first-of-type {
      margin-left: 15px;
  }

  .tags-view-container .tags-view-wrapper .tags-view-item:last-of-type {
      margin-right: 15px;
  }

  .tags-view-container .tags-view-wrapper .tags-view-item.active {
      /*background-color: #42b983;*/
      /*color: #fff;*/
      /*border-color: #42b983;*/
  }

  .tags-view-container .tags-view-wrapper .tags-view-item.active::before {
      content: "";
      background: #2d8cf0;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: relative;
      margin-right: 2px;
  }

  .tags-view-container .contextmenu {
      margin: 0;
      background: #fff;
      z-index: 3000;
      position: absolute;
      list-style-type: none;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #333;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  }

  .tags-view-container .contextmenu li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
  }

  .tags-view-container .contextmenu li:hover {
      background: #eee;
  }
</style>
<style lang="less" scoped>
a{
  text-decoration:none !important;
}
.container {
  height: 100%;
  overflow: hidden;
  .top {
    height: 8%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
    .logobox {
      padding-left: 3rem;
      display: flex;
      align-items: center;
    }

    .rightbox {
      .userbox {
        padding-right: 3rem;
        height: 100%;
        display: flex;
        align-items: center;

        .el-dropdown-link {
          cursor: pointer;
          display: flex;
          align-items: center;

          .headportrait {
            height: 5rem;
            width: 5rem;
            background-color: #c0c4cc;
            border-radius: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 2rem;
            font-weight: 600;
          }

          .username {
            color: #000;
            font-size: 1.4rem;
            margin-left: 1rem;
          }
        }

        .el-icon-arrow-down {
          font-size: 12px;
        }

        .demonstration {
          display: block;
          color: #8492a6;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }

  }

  .center {
    height: 92%;
    width: 100%;
    display: flex;

    .left_div {
      width: 210px;
      min-width: 210px;
      height: 100%;
      background-color: #314158;
      .mymennu {
        height: 100%;
        width: 100% !important;
      }
    }
    .right_div {
      width: 89%;
      height: 100%;
      .mianbao {
        width: 100%;
        overflow: hidden;
        background-color: #ffffff;
        border-bottom: 1px solid #e6e6e6;
        height: 5%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;

        .el-tag+.el-tag {
          margin-left: 10px;
        }

        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
      .chukou {
        width: 100%;
        background-color: #fafafa;
        padding-bottom: 20PX;
        padding-top: 34px;
        box-sizing: border-box;
      }
      
    }
  }
}
</style>