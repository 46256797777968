<template>
  <div class="container">
    <!-- <img :src="img2" alt="" class="img2"> -->
    <div class="formbox">
      <div class="logobox">
        <img src="../assets/imgs/logo1.png">
      </div>
      <form action="">
        <div class="shurubox">
          <div class="ibox">
            <i class="iconfont icon-wode"></i>
          </div>
          <input type="text" v-model="ruleform.username" placeholder="请输入您的账号" @blur="regular1()" />
        </div>
        <div class="errors">
          {{ errors.use_e }}
        </div>
        <div class="shurubox">
          <div class="ibox">
            <i class="iconfont icon-mima"></i>
          </div>
          <input type="password" v-model="ruleform.password" placeholder="请输入您的密码" @blur="regular2()" />
        </div>
        <div class="errors">
          {{ errors.pwd_e }}
        </div>
        <!-- <div class="checkarea">
          <div class="checkbox">
            <div class="ibox2">
              <i class="iconfont icon-yanzhengma"></i>
            </div>
            <input type="text" v-model="ruleform.code" placeholder="请输入您的验证码" @blur="regular3()" />
          </div>
          <div class="yzbtbox">
            <button type="button" @click="displayPhone()">获取验证码</button>
          </div>
        </div> -->
         <!--
        <div class="errors">
          {{ errors.yzm_e }}
        </div> -->
        <div class="denglubox">
          <button type="button" @click="tijiao()" @keyup.enter="keyDown(e)">登录</button>
        </div>
        <div class="errors">
          {{ errors.houtai_e }}
        </div>
      </form>
    </div>
    <div class="button_bgbox">
      <div class="wenzi">
        <img src="../assets/imgs/galogo.png"> 
        <el-link href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022028699号</el-link> 
        ©CopyRight 2022-2023, www.scdamc-gz.com, Inc.All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { login, getUserByToken, getNavigationBar,instance } from '../apis/apis.js'
import router from '@/router'
import {resetRouter, filterAsyncRouter} from '@/router/index'
import store from "../store/index";
export default {
  data() {
    return {
      //img1: require("../assets/imgs/LOGO.png"),

      //数据
      ruleform: {
        username: "",
        password: "",
        //code: "",
      },
      errors: {
        use_e: "",
        pwd_e: "",
        yzm_e: "",
        houtai_e: "",
      },
      logining: false,
      loading:null
    };
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown)

    this.verifyLogin(); 
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13) {
        this.tijiao();  
      } 
    },
    //用户名前端验证
    regular1() {
      var reg = /^\w{4,16}$/;
      if (reg.test(this.ruleform.username)) {
        this.errors.use_e = "";
      } else {
        this.errors.use_e = "用户名只能为4-16位数字或字母,不能为空";
      }
    },
    //密码前端验证
    regular2() {
      var reg = /^\w{4,16}$/;
      if (reg.test(this.ruleform.password)) {
        this.errors.pwd_e = "";
      } else {
        this.errors.pwd_e = "密码只能为4-16位数字或字母,不能为空";
      }
    },
    //验证码前端验证
    regular3() {
      var reg = /^\w{4}$/;
      if (reg.test(this.ruleform.code)) {
        this.errors.yzm_e = "";
      } else {
        this.errors.yzm_e = "验证码只能为4位数字或字母,不能为空";
      }
    },
    //发送验证码
    displayPhone() {
      this.$message({
        showClose: true,
        message: "已成功向13558844632发送验证码，请注意查收",
        type: "success",
        duration: 0,
      });
    },
    //通过前端验证，发送数据到后台
    tijiao() {
      var _this = this;
      this.regular1();
      this.regular2();
      //this.regular3();
      if (
        this.errors.use_e == "" &&
        this.errors.pwd_e == "" 
        //&&this.errors.yzm_e == ""
      ) {
        login(this.ruleform.username, this.ruleform.password).then(res => {
          if (res.data.success) {
            var token = res.data.response.token;
            _this.$store.commit("saveToken", token);

            var curTime = new Date();
            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.data.response.expires_in));
            _this.$store.commit("saveTokenExpire", expiredate);

            window.localStorage.refreshtime = expiredate;
            window.localStorage.expires_in = res.data.response.expires_in;

            //成功
            this.errors.houtai_e = "";
            //this.$router.push("/main/promanage");
            //保存tk值
            var token = res.data.response.token;
            window.localStorage.setItem("cftoken", token);
           
            if(instance.defaults.headers.Authorization!=null){
              instance.defaults.headers.Authorization = 'Bearer ' + token;  
            } 
            this.getUserInfoByToken(token)
          } else {
            this.$message.error("用户名、密码或验证码填写不正确");
            this.errors.houtai_e = "用户名、密码或验证码填写不正确"
          }
        })
      } else {
        this.$message.error("用户名、密码或验证码填写不正确");
      }
    },
    // 获取用户数据
    getUserInfoByToken(token) {
      var _this = this;
      var loginParams = { token: token };
      getUserByToken(loginParams).then(data => {

        if (!data.success) {
          _this.$message({
            message: data.msg,
            type: 'error'
          });

          if(this.loading!=null){
            this.loading.close();
          } 
        } else { 
          //this.errors.houtai_e = "接收到用户数据，开始初始化路由树...";
          window.localStorage.user = JSON.stringify(data.response)
          if (data.response.uID > 0) {
            this.GetNavigationBar(data.response.uID)
          }
        }
      });
    },
    // 获取路由树
    GetNavigationBar(uid) {
      var _this = this;
      var loginParams = { uid: uid, t: new Date() };

      getNavigationBar(loginParams).then(data => {
        _this.logining = false;
        if(this.loading!=null){
          this.loading.close();
        } 
        if(data==null||data.success==null){
          this.errors.houtai_e ='';
          _this.$message({
            message: '请重新登录',
            type: 'error'
          });
        }
        else if (!data.success) {
          _this.$message({
            message: data.msg,
            type: 'error'
          });
        } else {

          // _this.closeAlert()

          // _this.$message({
          //   message: "后台初始化成功",
          //   type: 'success'
          // });


          let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null);
          _this.$notify({
            type: "success",
            message: `登录成功 \n 欢迎：${userinfo.uRealName} `,
            duration: 6000
          });
//！Token 将在 ${window.localStorage.expires_in / 60} 分钟后过期！

          window.localStorage.router = (JSON.stringify(data.response.children));
          let getRouter = data.response.children//后台拿到路由
          getRouter = filterAsyncRouter(getRouter) //过滤路由
          router.$addRoutes(getRouter) //动态添加路由
          console.log(getRouter)
          console.log(router)
          // console.log(getRouter);
          // console.log(_this.$route.query.redirect);
          _this.$router.replace("/home/home");
          
        }
      });
    },
    verifyLogin(){
     var tk = store.state.token; 
     if(tk!=null && tk!="undefined"&&tk.length>0){ 
      this.loading = this.$loading({
          lock: true,
          text: '自动登录中，请稍等...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.getUserInfoByToken(tk);
     } 
    },
  },
};
</script>

<style lang="less" scoped>
/* 有了 scoped 后，表示下面的样式只对当前页面起效*/
.container {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  background-image: url(../assets/imgs/login_bg1.png);
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;

  .formbox {
    position: absolute;
    left: 57.65625%;
    top: 22%;
    width: 36rem;

    .logobox {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;

      img {
        width: 35.8rem;
      }
    }

    form {
      .errors {
        color: red;
        height: 3rem;
        font-size: 1.4rem;
        padding-left: 5rem;
      }

      .shurubox {
        width: 100%;
        border: 0.1rem solid #dcdfe6;
        height: 4.6rem;
        border-radius: 0.6rem;
        display: flex;
        align-items: center;

        .ibox {
          width: 5.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          .icon-wode {
            font-size: 2.4rem;
            color: #c0c4cc;
          }

          .icon-mima {
            font-size: 2.65rem;
            color: #c0c4cc;
          }
        }

        input {
          width: 100%-5.6rem;
          outline: none;
          border: none;
          height: 90%;
          font-size: 1.6rem;
          border-radius: 0.6rem;
        }
      }

      .checkarea {
        width: 100%;
        height: 5rem;
        display: flex;
        justify-content: space-between;

        .checkbox {
          width: 66%;
          border: 0.1rem solid #dcdfe6;
          border-radius: 0.6rem;
          height: 100%;
          display: flex;
          align-items: center;

          input {
            width: 100%-5.6rem;
            border: none;
            outline: none;
            height: 90%;
            font-size: 1.6rem;
            border-radius: 0.6rem;
          }

          .ibox2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5.6rem;
            height: 100%;

            .icon-yanzhengma {
              font-size: 2.4rem;
              color: #c0c4cc;
            }
          }
        }

        .yzbtbox {
          width: 30%;

          button {
            width: 100%;
            height: 100%;
            cursor: pointer;
            border-radius: 0.6rem;
            font-size: 1.4rem;
            border: none;
            color: #fff;
            background-color: #3b7ffe;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .denglubox {
        width: 100%;
        height: 5rem;
        margin-top: 2rem;

        button {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 1.8rem;
          cursor: pointer;
          background-color: #3b7ffe;
          color: #fff;
          border-radius: 0.6rem;
        }
      }
    }
  }

  .button_bgbox {
    width: 100%;
    height: 25rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(../assets/imgs/login_bg2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
    display: flex;
    justify-content: center;
    .wenzi{
      display: flex;
      align-items: center;
      padding-top: 18.0rem;
      img{
        margin-right: 0.2rem;
      }
    }
  }
}
</style>