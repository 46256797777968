import axios from "axios";
const IP ="https://api.scdamc-gz.com"
//const IP ="http://localhost:9291"
//const IP ="https://gz.yunshuduan.com"
import router from '../router/index'
import store from "../store/index";
import Vue from 'vue';

//const DataApi ="http://localhost:9291"
const DataApi ="https://api.scdamc-gz.com"
//const DataApi ="https://gz.yunshuduan.com"
//川发系统LOGO发布需更新，首页登录页标签
//川发系统不需要租赁数据

export const BaseApiUrl =IP;
export const message = ''

// 请求延时
axios.defaults.timeout = 20000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }
        saveRefreshtime();
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => {  
        return response; 
    },
    error => { 
        return responseError(error)
    }
);

function responseError(error){
    let errInfo = { success: false, message: "错误" }
    // 超时请求处理
    var originalRequest = error.config;
    if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 && !originalRequest._retry){
        errInfo.message = "请求超时！"; 
        originalRequest._retry = true 
    }else if (error.response) {
        if (error.response.status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // 在用户操作的活跃期内
            if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                return  refreshToken({token: window.localStorage.Token}).then((res) => {
                    if (res.success) {
                        Vue.prototype.$message({
                            message: 'refreshToken success! loading data...',
                            type: 'success'
                        });

                        store.commit("saveToken", res.response.token);

                        var curTime = new Date();
                        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expires_in));
                        store.commit("saveTokenExpire", expiredate);

                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = 'Bearer ' + res.response.token;
                        return axios(error.config);
                    } else {
                        // 刷新token失败 清除token信息并跳转到登录页面
                        ToLogin()
                    }
                });
            } else {
                // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                ToLogin()
            }

        }
        // 403 无权限
        else if (error.response.status == 403) {
           errInfo.message = "失败！该操作无权限"; 
        }
        // 429 ip限流
        else if (error.response.status == 429) {
            errInfo.message = "刷新次数过多，请稍事休息重试！";
        }else if (error.response.status == 404) {
            // 404 不存在
            errInfo.message = "失败！访问接口不存在";
       }else if (error.response.status == 500) {
           // 500 服务器异常
           errInfo.message = "失败！服务器异常"; 
       }else if (error.response.status == 405) {
           // 405 请求http方法错误
           errInfo.message = "失败！请求http方法错误";  
       }else if (error.response.status == 415) {
           // 415 参数没有指定Body还是Query
           errInfo.message = "失败！参数没有指定Body还是Query";   
       }else {
           //其他错误参数
            errInfo.message = '失败！请求错误' + error.response.status;    
       }
    }else{
        errInfo.message = "失败！服务器断开";  
    }
    Vue.prototype.$message({
        message: errInfo.message,
        type: 'error'
    });
    return errInfo; // 返回接口返回的错误信息
}

//读取token值
var nowedit1= localStorage.getItem('cftoken');

//登录请求
export function login(acc,pwd){
    return axios.get(IP+'/api/Login/JWTToken3.0?name='+acc+'&pass='+pwd)
};


export const saveRefreshtime = params => {

    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))

    let refreshCount=1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime=nowtime>expiretime ? nowtime:expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    }else {
        window.localStorage.refreshtime = new Date(-1);
    }
};
 const ToLogin = params => {
     
     store.commit("saveToken", "");
     store.commit("saveTokenExpire", "");
     store.commit("saveTagsData", "");
     window.localStorage.removeItem('user');
     window.localStorage.removeItem('NavigationBar');
 
        router.replace({
            path: "/login",
            query: {redirect: router.currentRoute.fullPath}
        });  
};

//首页
//获取用户信息
export function userinfor(){
    return axios.get(IP+'/api/User/GetInfoByToken?token='+nowedit1)
};
export function userinfor1(keyzhi){
    return axios.get(IP+'/api/User/GetInfoByToken?token='+keyzhi)
};

export const getNavigationBar = params => {
    return axios.get(`${IP}/api/permission/GetNavigationBar`, {params: params}).then(res => res.data);
};
export const getUserByToken = params => {
    return axios.get(`${IP}/api/user/getInfoByToken`, {params: params}).then(res => res.data);
};


const instance = createaxios();

function createaxios(){
    const instance2 = axios.create({
        baseURL: IP + '/api',
        headers: {'Authorization':'Bearer ' +localStorage.getItem('cftoken')}
    });
    instance2.interceptors.response.use(
        response => {  
            return response; 
        },
        error => {  
            return responseError(error)
        }
    ); 
    return instance2;
}

const Datainstance = axios.create({
    baseURL: DataApi + '/api',
    headers: {'Authorization':'Bearer ' +nowedit1}
});

//项目
export const addAssetPro = params => {
    return axios.post(`${IP}/api/AssetProject/Post`, params);
};
 
// 用户管理
export const getUserListPage = params => {
    return axios.get(`${IP}/api/user/get`, {params: params});
}; 
export const GetAllUserList = params => {
    return axios.get(`${IP}/api/user/GetAllUserList`, {params: params});
};
export const removeUser = params => {
    return axios.delete(`${IP}/api/user/delete`, {params: params});
};
export const editUser = params => {
    return axios.put(`${IP}/api/user/put`, params);
};
export const addUser = params => {
    return axios.post(`${IP}/api/user/post`, params);
};
export const batchRemoveUser = params => {
    return axios.delete(`${IP}/api/Claims/BatchDelete`, {params: params});//没做
};
export const editUserInfo = params => {
    return axios.put(`${IP}/api/user/PutInfo`, params);
};
export const getDepartmentUser = params => {
    return axios.get(`${IP}/api/User/GetDepartmentUser`, {params: params});
};

// 角色管理
export const getRoleListPage = params => {
    return axios.get(`${IP}/api/role/get`, {params: params});
};
export const removeRole = params => {
    return axios.delete(`${IP}/api/role/delete`, {params: params});
};
export const editRole = params => {
    return axios.put(`${IP}/api/role/put`, params);
};
export const addRole = params => {
    return axios.post(`${IP}/api/role/post`, params);
};


// 接口模块管理
export const getModuleListPage = params => {
    return axios.get(`${IP}/api/module/get`, {params: params});
};
export const removeModule = params => {
    return axios.delete(`${IP}/api/module/delete`, {params: params});
};
export const editModule = params => {
    return axios.put(`${IP}/api/module/put`, params);
};
export const addModule = params => {
    return axios.post(`${IP}/api/module/post`, params);
};


// 菜单模块管理
export const getPermissionListPage = params => {
    return axios.get(`${IP}/api/permission/get`, {params: params});
};
export const getPermissionTreeTable = params => {
    return axios.get(`${IP}/api/permission/GetTreeTable`, {params: params});
};
export const removePermission = params => {
    return axios.delete(`${IP}/api/permission/delete`, {params: params});
};
export const editPermission = params => {
    return axios.put(`${IP}/api/permission/put`, params);
};
export const addPermission = params => {
    return axios.post(`${IP}/api/permission/post`, params);
};
export const getPermissionTree = params => {
    return axios.get(`${IP}/api/permission/getpermissiontree`, {params: params});
};
export const getPermissionIds = params => {
    return axios.get(`${IP}/api/permission/GetPermissionIdByRoleId`, {params: params});
};

export const addRolePermission = params => {
    return axios.post(`${IP}/api/permission/Assign`, params);
};  

// 部门模块管理
export const getDepartmentListPage = params => {
    return axios.get(`${IP}/api/department/get`, {params: params});
};
export const getDepartmentTreeTable = params => {
    return axios.get(`${IP}/api/department/getTreeTable`, {params: params});
};

export const removeDepartment = params => {
    return axios.delete(`${IP}/api/department/delete`, {params: params});
};
export const editDepartment = params => {
    return axios.put(`${IP}/api/department/put`, params);
};
export const addDepartment = params => {
    return axios.post(`${IP}/api/department/post`, params);
};
export const getDepartmentTree = params => {
    return axios.get(`${IP}/api/department/getDepartmentTree`, {params: params});
};


// 日志
export const getLogs = params => {
    return axios.get(`${IP}/api/Monitor/get`, {params: params});
};
export const getRequestApiinfoByWeek = params => {
    return axios.get(`${IP}/api/Monitor/GetRequestApiinfoByWeek`, {params: params});
};
export const getAccessApiByDate = params => {
    return axios.get(`${IP}/api/Monitor/GetAccessApiByDate`, {params: params});
};
export const getAccessApiByHour = params => {
    return axios.get(`${IP}/api/Monitor/GetAccessApiByHour`, {params: params});
};
export const getServerInfo = params => {
    return axios.get(`${IP}/api/Monitor/Server`, {params: params});
};
export const getAccessLogs = params => {
    return axios.get(`${IP}/api/Monitor/GetAccessLogs`, {params: params});
};
export const getIds4UsersGrow = params => {
    return axios.get(`${IP}/api/Monitor/GetIds4Users`, {params: params});
};
export const getActiveUsers = params => {
    return axios.get(`${IP}/api/Monitor/GetActiveUsers`, {params: params});
};

//资产类型
export const getAssetTypeListPage = params => {
    return axios.get(`${IP}/api/AssetType/get`, {params: params});
};
export const removeAssetType = params => {
    return axios.delete(`${IP}/api/AssetType/delete`, {params: params});
};
export const editAssetType = params => {
    return axios.put(`${IP}/api/AssetType/put`, params);
};
export const addAssetType = params => {
    return axios.post(`${IP}/api/AssetType/post`, params);
};

//资产类型值
export const getAssetTypeValueListPage = params => {
    return axios.get(`${IP}/api/AssetTypeValue/get`, {params: params});
};
export const getAssetTypeValueListPage2 = params => {
    return axios.get(`${IP}/api/AssetTypeValue/getlist`, {params: params});
};
export const getAssetTypeValueByAsset= params => {
    return axios.get(`${IP}/api/AssetTypeValue/GetAssetInfoValue`, {params: params});
}; 
export const removeAssetTypeValue = params => {
    return axios.delete(`${IP}/api/AssetTypeValue/delete`, {params: params});
};
export const editAssetTypeValue = params => {
    return axios.put(`${IP}/api/AssetTypeValue/put`, params);
};
export const addAssetTypeValue = params => {
    return axios.post(`${IP}/api/AssetTypeValue/post`, params);
};

//测算类型
export const getComputeTypeListPage = params => {
    return axios.get(`${IP}/api/ComputeType/get`, {params: params});
};
export const removeComputeType = params => {
    return axios.delete(`${IP}/api/ComputeType/delete`, {params: params});
};
export const editComputeType = params => {
    return axios.put(`${IP}/api/ComputeType/put`, params);
};
export const addComputeType = params => {
    return axios.post(`${IP}/api/ComputeType/post`, params);
};
export const getComputeTypeByAssetType = params => {
    return axios.get(`${IP}/api/ComputeType/GetListByAssetType`, {params: params});
};

//测算类型计算值
export const getComputeTypeValueListPage = params => {
    return axios.get(`${IP}/api/ComputeTypeValue/get`, {params: params});
};
export const removeComputeTypeValue = params => {
    return axios.delete(`${IP}/api/ComputeTypeValue/delete`, {params: params});
};
export const editComputeTypeValue = params => {
    return axios.put(`${IP}/api/ComputeTypeValue/put`, params);
};
export const addComputeTypeValue = params => {
    return axios.post(`${IP}/api/ComputeTypeValue/post`, params);
};

//所属权人
export const getCustomersByFamily = params => {
    return axios.get(`${IP}/api/Customers/GetListByFamily`, {params: params});
};

//资产详细
export const getAssetsInfoListPage = params => {
    return axios.get(`${IP}/api/AssetsInfo/get`, {params: params});
};
export const removeAssetsInfo = params => {
    return axios.delete(`${IP}/api/AssetsInfo/delete`, {params: params});
};
export const editAssetsInfo = params => {
    return axios.put(`${IP}/api/AssetsInfo/put`, params);
};
export const addAssetsInfo = params => {
    return axios.post(`${IP}/api/AssetsInfo/post`, params);
};
export const editAssetCompute = params => {
    return axios.put(`${IP}/api/AssetsInfo/UpdAssetCompute`, params);
};
export const getAssetsDataListPage = params => {
    return axios.get(`${IP}/api/AssetsInfo/getlist`, {params: params});
};

//测算
export const AssetComputeApi = params => {
    return axios.put(`${IP}/api/AssetsInfo/AssetComputes`, params);
};
export const getAssetLog = params => {
    return axios.get(`${IP}/api/ComputeLog/GetAssetLog`, {params: params});
};

//文件类
export const getFilesTableListPage = params => {
    return axios.get(`${IP}/api/FilesTable/get`, {params: params});
};
export const getFilesTableList = params => {
    return axios.get(`${IP}/api/FilesTable/getlist`, {params: params});
};
export const removeFilesTable = params => {
    return axios.delete(`${IP}/api/FilesTable/delete`, {params: params});
}; 

export const GetNearSphere = params => {
    return axios.get(`${IP}/api/Map/GetList?type=1&mappoint=104.1585114762%7C30.0145270536875&kil=300`, params);
};

//操作日志
export const getSysOperatePage = params => {
  return axios.get(`${IP}/api/SysOperateLog/get`, {params: params});
};
export const addSysOperateLog = params => {
  return axios.post(`${IP}/api/SysOperateLog/post`, params);
};

//项目资产包
export const getAssetProjectPage = params => {
    return axios.get(`${IP}/api/AssetProject/get`, {params: params});
};
  
//数据类
export const getNearSphereLand = params => {
    return axios.get(`${IP}/api/Map/GetNearbyLandList`,  {params: params});
};
export const getNearSphereHouse = params => {
    return axios.get(`${IP}/api/Map/GetNearbyHouseList`,  {params: params});
};
export const getBaseLandPriceValue = params => {
    return axios.post(`${IP}/api/Map/GetBaseLandPriceValue`, params);
};
export const getBaseLandValue = params => {
    return axios.get(`${IP}/api/Map/GetBaseLandValue`, {params: params});
};
export {instance}
export {Datainstance}